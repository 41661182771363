"use client";
import React, { useEffect } from "react";
import { animationCreate } from "@/utils/utils"; // Ensure this is a named export
import BacktoTop from "@/components/common/backToTop/BacktoTop";
import HeaderTwo from "./header/HeaderTwo";
import FooterTwo from "./footer/FooterTwo";

// Dynamically require Bootstrap JS only on the client-side
if (typeof window !== "undefined") {
  require("bootstrap/dist/js/bootstrap");
}

interface WrapperProps {
  children: React.ReactNode;
}

const WrapperStyleOne: React.FC<WrapperProps> = ({ children }) => {
  

  return (
    <>
      <BacktoTop />
      <HeaderTwo />
      {children}
      <FooterTwo />
    </>
  );
};

export default WrapperStyleOne;
